<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Documents Texts</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseSection-5`"
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseSection-5`"
        visible
      >
        <div class="card-body">
          <div class="row">
            <div class="col-6 mb-2">
              <h3>Acceptance Document</h3>
              <div class="mb-1">
                <!-- <label
                  for=""
                  class="form-label"
                >Acceptance Report</label> -->
                <quill-editor
                  v-model="ar"
                />
              </div>
            </div>
            <div class="col-6 mb-2">
              <h3>Justification Document</h3>
              <div class="mb-1">
                <!-- <label
                  for=""
                  class="form-label"
                >Justification Report</label> -->
                <quill-editor v-model="jr" />
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <h3>Scientific memoir</h3>
                  <div class="mb-1">
                    <label
                      class="form-label"
                      for=""
                    >Upload scientific memoir template document</label>
                    <input
                      :ref="'scientific_memory'"
                      type="file"
                      class="form-control col-3"
                      @change="uploadFile('scientific_memory', 10)"
                    >
                    <p
                      v-if="convo.id && convo.academy_scientific_memory"
                    >Current file: <span class="text-success">{{ convo.academy_scientific_memory.name }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <h3>Economy memoir</h3>
                  <div class="mb-1">
                    <label
                      class="form-label"
                      for=""
                    >Upload economy memoir template document</label>
                    <input
                      :ref="'economy_memory'"
                      type="file"
                      class="form-control"
                      @change="uploadFile('economy_memory', 11)"
                    >
                    <p
                      v-if="convo.id && convo.academy_economy_memory"
                    >Current file: <span class="text-success">{{ convo.academy_economy_memory.name }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },
  data() {
    return {
      af: '',
      ar: '',
      jf: '',
      jr: '',
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  watch: {
    /* af() {
      this.saveAnswer('acceptance_form', this.af)
    }, */
    ar() {
      this.saveAnswer('acceptance_report', this.ar)
    },
    /* jf() {
      this.saveAnswer('justification_form', this.jf)
    }, */
    jr() {
      this.saveAnswer('justification_report', this.jr)
    },
    'convo.id': function () {
      if (this.convo && this.convo.id) {
        // this.af = this.convo.acceptance_form
        this.ar = this.convo.acceptance_report
        // this.jf = this.convo.justification_form
        this.jr = this.convo.justification_report
      } else {
        // this.af = ''
        this.ar = ''
        // this.jf = ''
        this.jr = ''
      }
    },
  },
  mounted() {
    if (this.convo && this.convo.id) {
      // this.af = this.convo.acceptance_form
      this.ar = this.convo.acceptance_report
      // this.jf = this.convo.justification_form
      this.jr = this.convo.justification_report
    } else {
      // this.af = ''
      this.ar = ''
      // this.jf = ''
      this.jr = ''
    }
  },
  methods: {
    uploadFile(ref, typeId) {
      const file = this.$refs[ref].files[0]

      if (file) {
        const newFile = {
          name: file.name,
          original_name: file.name,
          description: `${ref.split('_').join(' ')} document template`,
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          created_at: 'Pending to save...',
          file,
          file_type: file.type,
          type: typeId,
        }

        this.$emit('saveAnswer', { id: ref, data: newFile })

        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      }
    },
    saveAnswer(id, data) {
      this.$emit('saveAnswer', { id, data })
    },
  },
}
</script>
